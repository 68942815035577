<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
// import Multiselect from "vue-multiselect";
import { required } from "vuelidate/lib/validators";

/**
 * Form Validation component
 */
export default {
  page: {
    title: "Form Create Package",
    meta: [{ label: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Form Create Package",
      items: [
        {
          text: "Home",
          href: "/home",
        },
        {
          text: "Package",
          href: "/package",
        },
        {
          text: "Create Package",
          active: true,
        },
      ],
      form: {
        name: null,
        sku: null,
        price: null, 
        expiredIn: null,
        type: null
      },
      typesubmit: false,
      types: [
        { name: 'Buy 1 Get 1', value: 2 },
        { name: 'Buy 2 Get 1', value: 3 },
        { name: 'Buy 2 Get 2', value: 4 },
        { name: 'Buy 3 Get 1', value: 4 },
        { name: 'Buy 4 Get 1', value: 5 },
      ],
      isError: false,
      errorMessage: null,
    };
  },
  validations: {
    form: {
      name: { required },
      sku: { required },
      price: { required },
      description:{required},
      expiredIn: { required },
      // type: { required }
    },
  },
  methods: {
    formatRupiah() {
      const angka = this.convertToAngka(this.form.price)
      const rupaiah = this.convertToRupiah(angka, false)
      this.form.price = rupaiah
    },
    onSubmit(e) {
      this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.isError = true;
        this.errorMessage = "please fill the form with valid data";
      } else {

        let price = this.convertToAngka(this.form.price);
      
        let param = new FormData();
        param.append("name", this.form.name);
        param.append("sku", this.form.sku);
        param.append("price", price);
        param.append("description", this.form.description); 
        param.append("expire", this.form.expiredIn);
        param.append("type", JSON.stringify(this.form.type));

        console.log(param);

        this.$axios
          .post("/package", param)
          .then((response) => {
            console.log(response.data);
            this.$router.push(`/package/edit/${response.data.result.package._id}#treatment`);
          })
          .catch((e) => {
            if (e.response) {
              this.isError = true;
              this.errorMessage = e.response.data.message;
            }
          });
      }
    },
  },
 
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              v-model="isError"
              variant="danger"
              class="mt-3"
              dismissible
              >{{ errorMessage }}</b-alert
            >
            <form action="#" @submit.prevent="onSubmit">
              <div class="form-group">
                <label>Name</label>
                <input
                  v-model="form.name"
                  type="text"
                  class="form-control"
                  name="name"
                  :class="{ 'is-invalid': typesubmit && $v.form.name.$error }"
                />
                <div
                  v-if="typesubmit && $v.form.name.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.form.name.required"
                    >This value is required.</span
                  >
                </div>
              </div>
              <div class="form-group">
                <label>SKU</label>
                <input
                  v-model="form.sku"
                  type="text"
                  class="form-control"
                  name="sku"
                  :class="{ 'is-invalid': typesubmit && $v.form.sku.$error }"
                />
                <div v-if="typesubmit && $v.form.sku.$error" class="invalid-feedback">
                  <span v-if="!$v.form.sku.required">This value is required.</span>
                </div>
              </div>
              <div class="form-group">
                <label>Price</label>
                <b-input-group prepend="Rp.">
                  <input
                    v-model="form.price"
                    type="text"
                    class="form-control"
                    name="price"
                    @keyup="formatRupiah"
                    :class="{
                      'is-invalid': typesubmit && $v.form.price.$error,
                    }"
                  />
                  <div
                    v-if="typesubmit && $v.form.price.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.form.price.required"
                      >This value is required.</span
                    >
                  </div>
                </b-input-group>
              </div>
              <div class="form-group">
                <label :class="{ 'is-invalid': typesubmit && $v.form.expiredIn.$error }">Expire In</label>
                <b-input-group append="days">
                  <input
                    v-model="form.expiredIn"
                    type="text"
                    class="form-control"
                    name="expire"
                    :class="{ 'is-invalid': typesubmit && $v.form.expiredIn.$error }"
                  />
                </b-input-group>
                <div
                  v-if="typesubmit && $v.form.expiredIn.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.form.expiredIn.required"
                    >This value is required.</span
                  >
                </div>
              </div>
              <!-- <div class="form-group">
                <label>Type</label>
                <multiselect
                  :class="{ 'is-invalid': $v.form.type.$error }"
                  v-model="form.type"
                  deselect-label="Can't remove this value"
                  track-by="name"
                  label="name"
                  placeholder="Select one"
                  :options="types"
                  :searchable="false"
                  :allow-empty="false"
                >
                  <template slot="singleLabel" slot-scope="{ option }">
                    <strong>{{ option.name }}</strong>
                  </template>
                </multiselect>
                <div v-if="$v.form.type.$error" class="invalid-feedback">
                  <span>This value is required.</span>
                </div>
              </div> -->
              <div class="form-group">
                <label>Description</label>
                <div>
                  <textarea
                    v-model="form.description"
                    name="description"
                    class="form-control"
                    :class="{ 'is-invalid': typesubmit && $v.form.description.$error }"
                  ></textarea>
                  <div v-if="typesubmit && $v.form.description.$error" class="invalid-feedback">
                    <span v-if="!$v.form.description.required">This value is required.</span>
                  </div>
                </div>
              </div>

              <!-- <div class="form-group">
                <label>Status</label>
                <multiselect
                  :class="{ 'is-invalid': isStatusNotSelected }"
                  v-model="form.status"
                  deselect-label="Can't remove this value"
                  track-by="name"
                  label="name"
                  placeholder="Select one"
                  :options="options"
                  :searchable="false"
                  :allow-empty="false"
                >
                  <template slot="singleLabel" slot-scope="{ option }"
                    ><strong>{{ option.name }}</strong></template
                  >
                </multiselect>
                <div v-if="isStatusNotSelected" class="invalid-feedback">
                  <span>This value is required.</span>
                </div>
              </div> -->

              <div class="form-group mb-0">
                <div>
                  <button type="submit" class="btn btn-primary">Submit</button>
                </div>
              </div>
            </form>
          </div>
          <!-- end card-body -->
        </div>
      </div>
      <!-- end col-->
    </div>
    <!-- end row -->
  </Layout>
</template>